import { MotionDiv } from '@thg-commerce/gravity-animations'
import { css, styled } from '@thg-commerce/gravity-patterns/theme'
import {
  mq,
  spacing,
  Text,
  zIndex,
  ZIndexLevel,
} from '@thg-commerce/gravity-theme'

import {
  DropdownWrapper,
  SearchResultsDropdown,
} from '../HeaderSearch/SearchResultsDropdown/SearchResultsDropdown'
import { HeaderZIndexLevel } from '../types'

export const SearchContainer = styled.div<{ mobileSearch: boolean }>`
  display: flex;
  height: ${(props) => props.theme.patterns.header.search.height.mobile};

  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    display: inline-block;
    height: ${(props) => props.theme.patterns.header.search.height.desktop};

    ${(props) => `
      margin-bottom: ${
        props.theme.patterns.header.search.margin?.bottom ?? '0'
      };
      margin-top: ${props.theme.patterns.header.search.margin?.top ?? '0'};
    `}
  }

  ${(props) => props.mobileSearch && 'width: 100%'}
  flex-direction: column;
  flex: 1;
`

export const SearchForm = styled.form<{
  inputFocused: boolean
  mobileSearch: boolean
}>`
  display: flex;
  justify-content: center;
  height: 100%;
  &:focus {
    outline: ${(props) => props.theme.patterns.header.search.searchBarFocus};
  }
  ${(props) =>
    props.inputFocused &&
    `${zIndex(HeaderZIndexLevel.Dropdown)};
`}
  ${(props) =>
    `border-radius: ${
      props.theme.patterns.header.search.searchBorders.inactive.radius
    };

  border-top: ${
    props.theme.patterns.header.search.searchBorders.inactive.top.display &&
    `${props.theme.patterns.header.search.searchBorders.inactive.top.width} solid ${props.theme.patterns.header.search.searchBorders.inactive.top.color}`
  };
  border-left: ${
    props.theme.patterns.header.search.searchBorders.inactive.left.display &&
    `${props.theme.patterns.header.search.searchBorders.inactive.left.width} solid ${props.theme.patterns.header.search.searchBorders.inactive.left.color}`
  };
  border-right: ${
    props.theme.patterns.header.search.searchBorders.inactive.right.display &&
    `${props.theme.patterns.header.search.searchBorders.inactive.right.width} solid ${props.theme.patterns.header.search.searchBorders.inactive.right.color}`
  };
  border-bottom: ${
    props.theme.patterns.header.search.searchBorders.inactive.bottom.display &&
    `${props.theme.patterns.header.search.searchBorders.inactive.bottom.width} solid ${props.theme.patterns.header.search.searchBorders.inactive.bottom.color}`
  };
`}

  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    &:focus {
      outline: ${(props) => props.theme.patterns.header.search.searchBarFocus};
    }
    max-width: ${(props) =>
      props.theme.patterns.header.search.width
        ? props.theme.patterns.header.search.width
        : '458px'};
    width: ${(props) =>
      props.mobileSearch ? '100%' : props.theme.patterns.header.search.width};

    border-radius: ${(props) =>
      props.theme.patterns.header.search.searchBorders.inactive.radius};

    &:focus-within {
      ${(props) =>
        `border-radius: ${
          props.theme.patterns.header.search.searchBorders.active.radius
        };
  
        border-top: ${
          props.theme.patterns.header.search.searchBorders.active.top.display &&
          `${props.theme.patterns.header.search.searchBorders.active.top.width} solid ${props.theme.patterns.header.search.searchBorders.active.top.color}`
        };
        border-left: ${
          props.theme.patterns.header.search.searchBorders.active.left
            .display &&
          `${props.theme.patterns.header.search.searchBorders.active.left.width} solid ${props.theme.patterns.header.search.searchBorders.active.left.color}`
        };
        border-right: ${
          props.theme.patterns.header.search.searchBorders.active.right
            .display &&
          `${props.theme.patterns.header.search.searchBorders.active.right.width} solid ${props.theme.patterns.header.search.searchBorders.active.right.color}`
        };
        border-bottom: ${
          props.theme.patterns.header.search.searchBorders.active.bottom
            .display &&
          `${props.theme.patterns.header.search.searchBorders.active.bottom.width} solid ${props.theme.patterns.header.search.searchBorders.active.bottom.color}`
        };
        
        `}
    }
  }
`

export const SearchWrapper = styled.div<{
  inputFocused: boolean
  value: string
}>`
  ${(props) => props.inputFocused && `${zIndex(HeaderZIndexLevel.Dropdown)};`}
  display: flex;
  width: 100%;
  background-color: ${(props) =>
    props.value !== ''
      ? props.theme.patterns.header.search.backgroundColor.active
      : props.theme.patterns.header.search.backgroundColor.inactive};

  border-radius: ${(props) =>
    props.theme.patterns.header.search.searchBorders.inactive.radius};
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;

  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    background-color: ${(props) =>
      props.value !== ''
        ? props.theme.patterns.header.search.backgroundColor.active
        : props.theme.patterns.header.search.backgroundColor.inactive};
    width: 410px;

    &:focus-within {
      outline: none;
      padding: 0;
    }
  }
`

const placeholderStyling = css`
  ${(props) =>
    Text(
      props.theme.patterns.header.search.placeholderFont.entry,
      props.theme.patterns.header.search.placeholderFont.style,
    )};
  ${(props) => `
  text-transform: ${props.theme.patterns.header.search.placeholderFont.transform};
  text-decoration: ${props.theme.patterns.header.search.placeholderFont.textDecoration};
  color: ${props.theme.patterns.header.search.placeholderFont.textColor};
`}
`

export const SearchInput = styled.input<{ value: string }>`
  ${(props) =>
    Text(
      props.theme.patterns.header.search.activeFont.entry,
      props.theme.patterns.header.search.activeFont.style,
    )};
  ${(props) => `
    text-transform: ${props.theme.patterns.header.search.activeFont.transform};
    text-decoration: ${props.theme.patterns.header.search.activeFont.textDecoration};
    color: ${props.theme.patterns.header.search.activeFont.textColor};
  `}
  background-color: ${(props) =>
    props.value !== ''
      ? props.theme.patterns.header.search.backgroundColor.active
      : props.theme.patterns.header.search.backgroundColor.inactive};
  display: block;
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: ${(props) =>
    props.theme.patterns.header.search.searchBorders.inactive.radius};
  padding: ${(props) => props.theme.patterns.header.search.placeHolderPadding};

  ::placeholder {
    ${placeholderStyling}
    background-color: ${(props) =>
      props.value !== ''
        ? props.theme.patterns.header.search.backgroundColor.active
        : props.theme.patterns.header.search.backgroundColor.inactive};
  }
  ::-webkit-input-placeholder {
    ${placeholderStyling}
    background-color: ${(props) =>
      props.value !== ''
        ? props.theme.patterns.header.search.backgroundColor.active
        : props.theme.patterns.header.search.backgroundColor.inactive};
  }
  :-ms-input-placeholder {
    ${placeholderStyling}
    background-color: ${(props) =>
      props.value !== ''
        ? props.theme.patterns.header.search.backgroundColor.active
        : props.theme.patterns.header.search.backgroundColor.inactive};
  }

  &:focus {
    outline: none;
  }
`

export const SearchInputLabel = styled.label`
  width: 100%;
`

export const SearchButton = styled.button<{
  active: boolean
  inputFocused: boolean
}>`
  ${(props) => props.inputFocused && `${zIndex(HeaderZIndexLevel.Dropdown)};`}
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: ${spacing(6)};
  width: ${spacing(6)};
  height: ${spacing(6)};
  flex: 0;
  height: 100%;
  background-color: ${(props) =>
    props.active
      ? props.theme.patterns.header.search.buttonBackgroundColor.active
      : props.theme.patterns.header.search.buttonBackgroundColor.inactive};

  border-radius: ${(props) =>
    props.theme.patterns.header.search.searchBorders.inactive.radius};
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: ${(props) =>
    props.theme.patterns.header.search.buttonBorder.inactive.radius};
  border-bottom-right-radius: ${(props) =>
    props.theme.patterns.header.search.buttonBorder.inactive.radius};

  ${(props) =>
    `
  border-top: ${
    props.theme.patterns.header.search.buttonBorder.inactive.top.display &&
    `${props.theme.patterns.header.search.buttonBorder.inactive.top.width} solid ${props.theme.patterns.header.search.buttonBorder.inactive.top.color}`
  };
  border-left: ${
    props.theme.patterns.header.search.buttonBorder.inactive.left.display &&
    `${props.theme.patterns.header.search.buttonBorder.inactive.left.width} solid ${props.theme.patterns.header.search.buttonBorder.inactive.left.color}`
  };
  border-right: ${
    props.theme.patterns.header.search.buttonBorder.inactive.right.display &&
    `${props.theme.patterns.header.search.buttonBorder.inactive.right.width} solid ${props.theme.patterns.header.search.buttonBorder.inactive.right.color}`
  };
  border-bottom: ${
    props.theme.patterns.header.search.buttonBorder.inactive.bottom.display &&
    `${props.theme.patterns.header.search.buttonBorder.inactive.bottom.width} solid ${props.theme.patterns.header.search.buttonBorder.inactive.bottom.color}`
  };
`}

  svg {
    fill: ${(props) => props.theme.patterns.header.search.mobileIcon.color};
  }

  &:focus {
    outline: none;
    outline-offset: -4px;
    ${(props) =>
      props.active
        ? `outline: 2px solid ${props.theme.colors.palette.greys.white}`
        : `border: 2px solid ${
            props.theme.patterns.header.search.focusBorderColor ||
            props.theme.colors.palette.brand.base
          }`};
  }

  &:hover,
  &:active,
  &:focus {
    background-color: ${(props) =>
      props.active
        ? props.theme.patterns.header.search.buttonBackgroundColor.active
        : props.theme.patterns.header.search.buttonBackgroundColor.inactive};
    padding: 0 ${spacing(1)};
  }

  svg {
    fill: ${(props) =>
      props.active
        ? props.theme.patterns.header.search.iconColor.active
        : props.theme.patterns.header.search.iconColor.inactive};
  }
`

export const StyledSearchResultsDropdown = styled(SearchResultsDropdown)<{
  shouldDisplay: boolean
  mobileSearch: boolean
}>`
  ${(props) =>
    props.mobileSearch && 'top:15px; max-height: 500px;  overflow-y: auto;'};
  display: ${(props) => (props.shouldDisplay ? 'initial' : 'none')};
  ${(props) => (props.mobileSearch ? '  width: 470px' : null)};
`

export const ClearInput = styled.button`
  ${(props) =>
    Text(props.theme.formgroup.icon.scale, props.theme.formgroup.icon.style)};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${spacing(1)};
  border: 2px solid transparent;
  color: ${(props) => props.theme.colors.palette.greys.darker};

  &:hover,
  &:active,
  &:focus {
    opacity: 0.8;
    cursor: pointer;
    outline: none;
  }

  &:focus-visible {
    border-color: ${(props) => props.theme.colors.palette.brand.base};
  }

  svg {
    path {
      fill: ${(props) => props.theme.patterns.header.search.clearTextIcon};
    }
  }
`

export const DropdownOverlay = styled(MotionDiv)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  display: block;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  ${zIndex(HeaderZIndexLevel.Overlay)};
`

export const PromotionsDropdown = styled(DropdownWrapper)`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  min-width: 393px;
  padding: 0 ${spacing(2)};
`

export const StyledSearchDropdown = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  z-index: ${ZIndexLevel.Highest};
  border-top: 1px solid ${(props) => props.theme.colors.palette.greys.light};
  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    border-top: 0;
  }
`
